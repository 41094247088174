import React, { Component } from "react";
import Helmet from "react-helmet";
import { PageContent } from "../../../../../Interfaces/Props";
import HeaderImage from "../../../HeaderImage/HeaderImage";
import Renderer from "../../../Renderer/Renderer";

import "./About.scss";

class About extends Component<PageContent> {
  render() {
    return (
      <div
        id="about"
        style={{ backgroundImage: `url(${this.props.background})` }}
      >
        <Helmet>
          <title>About Eat Right Bucks County</title>
          <meta
            name="description"
            content="All abou thow my practice, eat right bucks county, can imporve your dietary health"
          />
        </Helmet>
        {this.props.header && <HeaderImage {...this.props.header} eat={true} />}
        <Renderer
          content={this.props.content}
          info={this.props.info}
          eatTheme={true}
        />
      </div>
    );
  }
}

export default About;
