import React, { Component } from "react";
import { NoticeProps } from "../../../Interfaces/Props";
import "./NoticeBar.scss";

class NoticeBar extends Component<NoticeProps> {
  render() {
    return this.props.notice ? (
      <div
        id="noticeBar"
        className={
          !this.props.notice || this.props.notice.length === 0
            ? "noticeBar-empty"
            : ""
        }
      >
        <span id="noticeBar-notice">{this.props.notice}</span>
      </div>
    ) : (
      <div id="noticeBar-empty"></div>
    );
  }
}

export default NoticeBar;
