import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router";
import {
  Content,
  NewsPage,
  RecipesPage,
  PageContent,
  ExpertisePage,
  ProfileInfo,
  SocialIcons,
  RecipeTag,
  BusinessInfo,
  Recipe,
  Article
} from "../Interfaces/Props";
import ScrollToTop from "./Components/Tools/ScrollToTop";
import Home from "./Components/Pages/Home/Home";
import Recipes from "./Components/Pages/Recipes/Recipes";
import RecipeListing from "./Components/Recipe/RecipeListing";
import News from "./Components/Pages/News/News";
import Expertise from "./Components/Pages/Expertise/Expertise";
import Eat from "./Components/Pages/Eat/Eat";
import About from "./Components/Pages/Eat/About/About";
import First from "./Components/Pages/Eat/First/First";
import Contact from "./Components/Pages/Eat/Contact/Contact";
import { makeLink } from "./Components/Tools/LinkTools";
import Profile from "./Components/Profile/Profile";
import NewsArticle from "./Components/Article/NewsArticle";
import Nav from "./Components/Nav/Nav";

import "./App.scss";
import Footer from "./Components/Footer/Footer";
import NoticeBar from "./Components/NoticeBar/NoticeBar";

require("es6-object-assign/auto");

const sanityClient = require("@sanity/client");

class App extends Component<any> {
  state: {
    news: NewsPage;
    recipes: RecipesPage;
    home: PageContent;
    expertise: ExpertisePage;
    profiles: ProfileInfo[];
    eatHome: PageContent;
    about: PageContent;
    first: PageContent;
    contact: PageContent;
    kristieBackground: string;
    eatBackground: string;
    kristieNav: string;
    eatNav: string;
    socialIcons: SocialIcons;
    recipeTags: RecipeTag[];
    businessInfo: BusinessInfo | undefined;
    urgentMessage: string | undefined;
    loaded: boolean;
    onEatRightPage: boolean;
  };
  client: any;

  paragraphQuery =
    "{text, links[]{linkText, linkURL}, paragraphStyles[]{textToStyle, bold, italics, color}}";

  paragraphImageQuery = `paragraphsWithImage{paragraphs[]${this.paragraphQuery}, paragraphImage[]{"image": image.asset->url, captionText${this.paragraphQuery}}, side}`;

  contentQuery = `${this.paragraphImageQuery}, title, display, "list": listContent{title, display, numbered, listContent[]${this.paragraphQuery}}, paragraphs[]${this.paragraphQuery}, images[]{"image": image.asset->url, captionText${this.paragraphQuery}}, imageSlideshow{images[]{"image": image.asset->url, imageTitle, imageSubtitle, readMoreLink}}, "video": videoURL, fileDownloadButton[]{downloadButtonText, "file": downloadButtonFile.asset->url},"formInfo": form{formTitle, formEmail, displayForm}, googleMapURL, insertSeparator, tags[], htmlContent, subtitle, scheduler`;

  profilesQuery = `"profiles": profileCards[]->{ name, "image": profilePicture.asset->url, qualifications[], expertise[], dietitianPageContent{${this.contentQuery}} }`;

  headingQuery = `pageHeading{"image": headerImage.asset->url, titleText}`;

  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      news: { pageContent: { content: [] } },
      recipes: {},
      home: { content: [] },
      expertise: {},
      profiles: [],
      eatHome: { content: [] },
      about: { content: [] },
      first: { content: [] },
      contact: { content: [] },
      kristieBackground: "",
      eatBackground: "",
      kristieNav: "",
      eatNav: "",
      socialIcons: { icons: [] },
      recipeTags: [],
      businessInfo: undefined,
      urgentMessage: undefined,
      onEatRightPage: window.location.href
        .toLowerCase()
        .includes("eatrightbuckscounty")
    };

    this.client = sanityClient({
      projectId: "ag1nn5tl",
      dataset: "website_content",
      useCdn: true
    });

    this.getBackgrounds();
    this.getNotice();
    this.getInfo();
    this.getSocialIcons();
    this.getRecipeTags();
    this.fetchNews();
    this.fetchRecipes();
    this.fetchExpertise();
    this.fetchProfiles();
    this.fetchPage("kristieHome");
    this.fetchPage("eatHome");
    this.fetchPage("eatAbout");
    this.fetchPage("eatContact");
    this.fetchPage("eatFirst");
    this.fetchPage("kristieNews");
    this.fetchPage("kristieRecipes");
    this.checkTheme();
  }

  componentDidMount() {
    this.checkTheme();
    this.setState({ loaded: true });
  }

  componentDidUpdate() {
    this.checkTheme();
  }

  checkTheme() {
    // prevent infinite render-update loop by only calling setState if there is a change
    const eatPage = window.location.href
      .toLowerCase()
      .includes("eatrightbuckscounty");
    if (eatPage !== this.state.onEatRightPage) {
      this.setState({
        onEatRightPage: eatPage
      });
    }
  }

  getBackgrounds() {
    this.client
      .fetch(
        `*[_type == "backgroundImages"]{"kfBackground": kfBackground.asset->url, "eatBackground": eatBackground.asset->url, "kristieNav": kristieNav.asset->url, "eatNav": eatNav.asset->url}[0]`
      )
      .then((result: any) => {
        if (result)
          this.setState({
            kristieBackground: result.kfBackground,
            eatBackground: result.eatBackground,
            kristieNav: result.kristieNav,
            eatNav: result.eatNav
          });
      });
  }

  getNotice() {
    this.client
      .fetch(`*[_type == "urgentNotice"]{noticeMessage}[0]`)
      .then((result: any) => {
        if (result) this.setState({ urgentMessage: result.noticeMessage });
      });
  }

  getInfo() {
    this.client
      .fetch(
        `*[_type == "businessInfo"]{phoneNumber, emailAddress, address, hours[]}[0]`
      )
      .then((result: any) => {
        if (result)
          this.setState({
            businessInfo: {
              phone: result.phoneNumber,
              email: result.emailAddress,
              address: result.address,
              hours: result.hours
            }
          });
      });
  }

  getSocialIcons() {
    this.client
      .fetch(
        `*[_type == "socialMediaPanel"]{icons[]{link, "icon": icon.asset->url}}[0]`
      )
      .then((result: any) => {
        if (result) this.setState({ socialIcons: { icons: result.icons } });
      });
  }

  getRecipeTags() {
    this.client
      .fetch(
        `*[_type == "recipeTags"][]{"name": fullName, "displayName": tagDisplayName, "color": tagColor}`
      )
      .then((result: any) => {
        if (result)
          this.setState({
            recipeTags: result,
            recipes: { ...this.state.recipes, recipeTags: result }
          });
      });
  }

  fetchNews() {
    this.client
      .fetch(
        `*[_type == "newsArticles"]{datePosted, articleTitle, articleContent[]{${this.contentQuery}}} | order(datePosted desc)`
      )
      .then((result: any) => {
        const d = new Date(); // use today as a backup date
        this.setState({
          news: {
            ...this.state.news,
            articles: result.map((article: any) => ({
              date: article.datePosted
                ? article.datePosted
                : `${d.getMonth()}/${d.getDay()}/${d.getFullYear()}`,
              title: article.articleTitle
                ? article.articleTitle
                : "News Update!",
              content: article.articleContent
                ? article.articleContent.map((item: any) =>
                    this.parseContent(item)
                  )
                : [{}]
            }))
          }
        });
      });
  }

  fetchRecipes() {
    this.client
      .fetch(
        `*[_type == "recipes"]{recipeName, "recipeOverview": recipeOverview[]{${this.contentQuery}}, recipeImages[]{"image": image.asset->url, captionText${this.paragraphQuery}}, recipeIngredients[]{ingredientName->{ingredientName, ingredientId}, quantity, unit}, recipeInstructions[]${this.paragraphQuery}, recipeNotes[]{${this.contentQuery}}, recipeTagList[]->{fullName, tagDisplayName, tagColor}}`
      )
      .then((result: any) => {
        this.setState({
          recipes: {
            ...this.state.recipes,
            recipes: result.map((recipe: any) => {
              return {
                name: recipe.recipeName ? recipe.recipeName : "New Recipe!",
                overview: this.getPageContent({
                  pageContent: { content: recipe.recipeOverview }
                }).content,
                images: this.parseImage(recipe.recipeImages),
                ingredients: this.parseIngredients(recipe.recipeIngredients),
                instructions: this.parseParagraphs(recipe.recipeInstructions),
                notes: recipe.recipeNotes
                  ? recipe.recipeNotes.map((note: any) =>
                      this.parseContent(note)
                    )
                  : undefined,
                tags: recipe.recipeTagList
                  ? this.parseTags(recipe.recipeTagList)
                  : undefined
              };
            })
          }
        });
      });
  }

  fetchExpertise() {
    this.client
      .fetch(
        `*[_type == "kristieExpertise"]{"pageContent": topContent{"header": ${this.headingQuery}, "content": webpageContent[]{${this.contentQuery}, ${this.profilesQuery}}}, "content": expertiseSections[]{expertiseTitle, expertiseArea[]{${this.contentQuery}}}}[0]`
      )
      .then((result: any) => {
        this.setState({
          expertise: {
            header: result.pageContent ? this.getPageContent(result) : {},
            specialties: result.content
              ? result.content.map((expertiseAreas: any) => {
                  return {
                    title: expertiseAreas.expertiseTitle,
                    content: expertiseAreas.expertiseArea.map(
                      (expertiseArea: any) => this.parseContent(expertiseArea)
                    )
                  };
                })
              : [[{}]]
          }
        });
      });
  }

  fetchProfiles() {
    this.client
      .fetch(
        `*[_type == "profiles"]{name, "image": profilePicture.asset->url, qualifications[], expertise[], "content": dietitianPageContent{"header": ${this.headingQuery}, "content": webpageContent[]{${this.contentQuery}}}}`
      )
      .then((result: any) => {
        this.parseProfiles(result, true);
      });
  }

  fetchPage(query: string) {
    this.client
      .fetch(
        `*[_type == "${query}"]{pageContent{"header": ${this.headingQuery}, "content": webpageContent[]{${this.contentQuery}, ${this.profilesQuery}}}}[0]`
      )
      .then((result: any) => {
        const content = this.getPageContent(result);
        switch (query) {
          case "kristieHome": {
            this.setState({ home: content });
            break;
          }
          case "eatHome": {
            this.setState({ eatHome: content });
            break;
          }
          case "eatAbout": {
            this.setState({ about: content });
            break;
          }
          case "eatContact": {
            this.setState({ contact: content });
            break;
          }
          case "eatFirst": {
            this.setState({ first: content });
            break;
          }
          case "kristieNews": {
            this.setState({
              news: { ...this.state.news, pageContent: content }
            });
            break;
          }
          case "kristieRecipes": {
            this.setState({
              recipes: { ...this.state.recipes, pageContent: content }
            });
            break;
          }
        }
      });
  }

  getPageContent(content: any): PageContent {
    if (!content || !content.pageContent || !content.pageContent.content)
      return { content: [{ title: "No Content For This Page" }] };

    let pageContent: PageContent = { header: undefined, content: [] };
    if (content && content.pageContent && content.pageContent.header) {
      const pageHeading = content.pageContent.header;
      pageContent.header = {
        text: pageHeading.titleText,
        image: pageHeading.image
      };
    }
    if (content.pageContent.content) {
      const contentList = content.pageContent.content;
      contentList.map((item: any) => {
        return pageContent.content!.push(this.parseContent(item));
      });
    }
    return pageContent;
  }

  parseContent(content: any): Content {
    let result = {};
    if (content.title) result = { ...result, title: content.title };
    result = { ...result, showTitle: content.display ? true : false };
    if (content.subtitle) result = { ...result, subtitle: content.subtitle };
    if (content.list)
      result = { ...result, list: this.parseList(content.list) };
    if (content.paragraphs)
      result = {
        ...result,
        paragraphs: this.parseParagraphs(content.paragraphs)
      };
    if (content.paragraphsWithImage)
      result = {
        ...result,
        paragraphsAndImage: this.parseParagraphsAndImage(
          content.paragraphsWithImage
        )
      };
    if (content.images)
      result = {
        ...result,
        images: this.parseImage(content.images)
      };
    if (content.imageSlideshow && content.imageSlideshow.images)
      result = {
        ...result,
        slideshow: this.parseSlideshow(content.imageSlideshow.images)
      };
    if (content.video) result = { ...result, video: content.video };
    if (content.fileDownloadButton)
      result = {
        ...result,
        files: content.fileDownloadButton.map((file: any) => ({
          text: file.downloadButtonText,
          file: file.file
        }))
      };
    if (content.profiles && content.profiles.length > 0)
      result = {
        ...result,
        profiles: this.parseProfiles(content.profiles, false)
      };
    if (content.formInfo && content.formInfo.displayForm)
      result = {
        ...result,
        contact: {
          title: content.formInfo.formTitle,
          email: content.formInfo.formEmail
        }
      };
    if (content.scheduler) result = { ...result, scheduler: content.scheduler };
    if (content.googleMapURL) result = { ...result, map: content.googleMapURL };
    if (content.htmlContent) result = { ...result, frame: content.htmlContent };
    if (content.insertSeparator) result = { ...result, separator: true };
    if (content.tags) result = { ...result, tags: content.tags };
    return result;
  }

  parseLink(link: any) {
    if (!link) return undefined;
    return {
      text: link.linkText,
      url: link.linkURL
    };
  }

  parseStyle(style: any) {
    if (!style) return undefined;
    return {
      text: style.textToStyle,
      bold: style.bold ? true : false,
      italics: style.italics ? true : false,
      color: style.color ? style.color : undefined
    };
  }

  parseParagraph(paragraph: any) {
    if (!paragraph) return undefined;
    return {
      text: paragraph.text,
      links: paragraph.links
        ? paragraph.links.map((link: any) => this.parseLink(link))
        : undefined,
      styles: paragraph.paragraphStyles
        ? paragraph.paragraphStyles.map((style: any) => this.parseStyle(style))
        : undefined
    };
  }

  parseParagraphs(paragraphs: any[]) {
    if (!paragraphs || paragraphs.length === 0) return undefined;
    return paragraphs.map((paragraph: any) => this.parseParagraph(paragraph));
  }

  parseList(list: any) {
    if (!list) return undefined;
    return {
      showTitle: list.display ? true : false,
      title: list.title && list.display ? list.title : undefined,
      numbered: list.numbered ? true : undefined,
      content: this.parseParagraphs(list.listContent)
    };
  }

  parseParagraphsAndImage(paragraphsAndImage: any) {
    if (!paragraphsAndImage) return undefined;
    return {
      paragraphs: this.parseParagraphs(paragraphsAndImage.paragraphs),
      image: this.parseImage(paragraphsAndImage.paragraphImage),
      alignment: paragraphsAndImage.side ? paragraphsAndImage.side : "Left"
    };
  }

  parseIngredients(ingredients: any[]) {
    if (!ingredients) return [];
    return ingredients.map((ingredient: any) => ({
      name: ingredient.ingredientName
        ? ingredient.ingredientName.ingredientName
        : "Unnamed",
      id: ingredient.ingredientName
        ? ingredient.ingredientName.ingredientId
        : 0,
      quantity: ingredient.quantity ? ingredient.quantity : 0,
      unit: ingredient.unit ? ingredient.unit : "g"
    }));
  }

  parseTags(tags: any[]) {
    return tags.map((tag: any) => ({
      name: tag.fullName,
      displayName: tag.tagDisplayName,
      color: tag.tagColor
    }));
  }

  parseImage(image: any[]) {
    if (!image) return undefined;
    return image.map((img: any) => ({
      image: img.image ? img.image : "Invalid Image",
      caption: img.captionText
        ? this.parseParagraph(img.captionText)
        : undefined
    }));
  }

  parseSlideshow(images: any[]) {
    if (!images.length || images.length === 0) return;
    return images.map((image: any) => ({
      url: image.image,
      title: image.imageTitle ? image.imageTitle : undefined,
      caption: image.imageSubtitle ? image.imageSubtitle : undefined,
      link: image.readMoreLink ? image.readMoreLink : undefined
    }));
  }

  parseProfiles(profiles: any[], save?: boolean) {
    if (!profiles) return undefined;
    let result = profiles.map((profile: any) => this.parseProfile(profile));
    if (save)
      this.setState({
        profiles: result
      });
    return result;
  }

  parseProfile(profile: any) {
    if (!profile) return undefined;
    return {
      name: profile.name,
      picture: profile.image ? profile.image : undefined,
      qualifications: profile.qualifications ? profile.qualifications : [],
      expertise: profile.expertise ? profile.expertise : [],
      content: this.getPageContent({ pageContent: profile.content })
    };
  }

  render() {
    this.checkTheme();
    return (
      <div
        id="app"
        style={{ visibility: this.state.loaded ? "visible" : "hidden" }}
      >
        {this.state.onEatRightPage && (
          <NoticeBar notice={this.state.urgentMessage} />
        )}
        <Nav
          onEatRightPage={this.state.onEatRightPage}
          kristieBackground={this.state.kristieNav}
          eatBackground={this.state.eatNav}
          icons={this.state.socialIcons.icons}
        />
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path="/recipes"
              render={props => (
                <Recipes
                  {...props}
                  {...this.state.recipes}
                  background={this.state.kristieBackground}
                  tags={this.state.recipeTags}
                  info={this.state.businessInfo}
                />
              )}
            />

            {this.state.recipes.recipes &&
              this.state.recipes.recipes.map((recipe: Recipe, i: number) => (
                <Route
                  key={i}
                  exact
                  path={"/recipes/" + makeLink(recipe.name)}
                  render={props => (
                    <RecipeListing
                      {...props}
                      recipe={recipe}
                      background={this.state.kristieBackground}
                      info={this.state.businessInfo}
                    />
                  )}
                />
              ))}

            <Route
              exact
              path="/news"
              render={props => (
                <News
                  {...props}
                  {...this.state.news}
                  background={this.state.kristieBackground}
                  info={this.state.businessInfo}
                />
              )}
            />

            {this.state.news.articles &&
              this.state.news.articles.map((article: Article, i: number) => (
                <Route
                  key={i}
                  exact
                  path={"/news/" + makeLink(article.title + article.date)}
                  render={props => (
                    <NewsArticle
                      {...props}
                      article={article}
                      background={this.state.kristieBackground}
                      info={this.state.businessInfo}
                    />
                  )}
                />
              ))}

            <Route
              exact
              path="/expertise"
              render={props => (
                <Expertise
                  {...props}
                  {...this.state.expertise}
                  background={this.state.kristieBackground}
                  info={this.state.businessInfo}
                />
              )}
            />

            {this.state.profiles.map((profile: ProfileInfo, i: number) => (
              <Route
                key={i}
                exact
                path={`/eatRightBucksCounty/${makeLink(profile.name)}`}
                render={props => (
                  <Profile
                    {...props}
                    {...profile}
                    info={this.state.businessInfo}
                    background={this.state.eatBackground}
                  />
                )}
              />
            ))}

            <Route
              exact
              path="/eatRightBucksCounty/services"
              render={props => (
                <About
                  {...props}
                  {...this.state.about}
                  info={this.state.businessInfo}
                  background={this.state.eatBackground}
                />
              )}
            />

            <Route
              exact
              path="/eatRightBucksCounty/firstVisit"
              render={props => (
                <First
                  {...props}
                  {...this.state.first}
                  info={this.state.businessInfo}
                  background={this.state.eatBackground}
                />
              )}
            />

            <Route
              exact
              path="/eatRightBucksCounty/contact"
              render={props => (
                <Contact
                  {...props}
                  {...this.state.contact}
                  info={this.state.businessInfo}
                  background={this.state.eatBackground}
                />
              )}
            />

            <Route
              path="/eatRightBucksCounty"
              render={props => (
                <Eat
                  {...props}
                  {...this.state.eatHome}
                  background={this.state.eatBackground}
                  info={this.state.businessInfo}
                />
              )}
            />

            <Route
              path="/"
              render={props => (
                <Home
                  {...props}
                  {...this.state.home}
                  info={this.state.businessInfo}
                  background={this.state.kristieBackground}
                />
              )}
            />
          </Switch>
          <Footer
            icons={this.state.socialIcons.icons}
            onEatRightPage={this.state.onEatRightPage}
          />
        </ScrollToTop>
      </div>
    );
  }
}

export default withRouter(App);
