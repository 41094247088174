// import { Icon } from "@material-ui/core";
// import Renderer from "../../Renderer/Renderer";
// import HeaderImage from "../../HeaderImage/HeaderImage";
// import Separator from "../../Separator/Separator";
import './Recipes.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';

import { Recipe, RecipesPage } from '../../../../Interfaces/Props';
import HeaderImage from '../../HeaderImage/HeaderImage';
import Renderer from '../../Renderer/Renderer';

// import { NavLink } from "react-router-dom";
// import { makeLink } from "../../Tools/LinkTools";
// import ImagePane from "../../ImagePane/ImagePane";

class Recipes extends Component<RecipesPage> {
  state: {
    selected: string[];
    searchText: string[];
    searchTags: string[];
    searched: boolean;
    search: string;
    results: boolean[];
    loaded: boolean;
    resultsView: string;
  };

  constructor(props: RecipesPage) {
    super(props);
    this.state = {
      selected: [],
      searchText: [],
      searched: false,
      searchTags: [],
      search: "",
      results: [],
      loaded: false,
      resultsView: "grid"
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  componentDidUpdate(props: RecipesPage) {
    if (!props.recipes || props.recipes.length === this.state.searchText.length)
      return;
    this.setState({
      searchText: props.recipes.map((recipe: Recipe) =>
        this.extractValues(recipe)
      )
    });
  }

  extractValues(obj: Object): string {
    return Object.values(obj)
      .map((value: any) => {
        if (typeof value === "object") {
          return this.extractValues(value);
        }
        return value;
      })
      .join(" ")
      .toLowerCase();
  }

  enterPress(e: any) {
    if (e.keyCode === 13) this.search();
  }

  search() {
    if (this.state.search.length > 0 || this.state.searchTags.length > 0) {
      this.setState({ searched: true });

      if (this.state.searchTags.length > 0) {
        let results: boolean[] = [];
        this.state.searchTags.map((tag: string, i: number) =>
          this.state.searchText.map((text: string, j: number) =>
            text.includes(tag.toLowerCase())
              ? (results[j] =
                  results[j] === undefined ? true : results[j] && true)
              : (results[j] = false)
          )
        );
        this.setState({
          results: results
        });
      } else {
        this.setState({
          results: this.state.searchText.map((text: string) =>
            text.includes(this.state.search.toLowerCase()) ? true : false
          )
        });
      }
      let service = document.getElementById("recipes-search");
      if (service) {
        window.scrollTo({
          top: service.offsetTop - 50,
          left: 0,
          behavior: "smooth"
        });

        // hide the mobile keyboard
        let element = document.getElementById("recipes-searchBar");
        if (element !== null) {
          // Force keyboard to hide on input field.
          element.setAttribute("readonly", "readonly");
          // Force keyboard to hide on textarea field.
          element.setAttribute("disabled", "true");
          setTimeout(function() {
            if (element !== null) {
              //actually close the keyboard
              element.blur();
              // Remove readonly attribute after keyboard is hidden.
              element.removeAttribute("readonly");
              element.removeAttribute("disabled");
            }
          }, 100);
        }
      }
    }
  }

  render() {
    return (
      <div
        id="recipes"
        style={{
          backgroundImage: `url(${this.props.background})`,
          visibility: this.state.loaded ? "visible" : "hidden"
        }}
      >
        <Helmet>
          <title>Recipes</title>
          <meta
            name="description"
            content="Kristie Finnan's healthy and delicious recipes for managing your dietary health"
          />
        </Helmet>

        {this.props.pageContent && this.props.pageContent.header && (
          <HeaderImage eat={false} {...this.props.pageContent.header} />
        )}

        {this.props.pageContent && this.props.pageContent.content && (
          <Renderer
            info={this.props.info}
            content={this.props.pageContent.content}
          />
        )}
        {/* <iframe
          src="https://celiacrd.com/"
          className="recipes-frame"
          title="Kristie Finnan's Recipe Book"
        ></iframe> */}
      </div>
    );
  }

  // render() {
  //   return (
  //     <div
  //       id="recipes"
  //       style={{
  //         backgroundImage: `url(${this.props.background})`,
  //         visibility: this.state.loaded ? "visible" : "hidden"
  //       }}
  //     >

  //       {this.props.pageContent && this.props.pageContent.header && (
  //         <HeaderImage eat={false} {...this.props.pageContent.header} />
  //       )}
  //       {this.props.pageContent && this.props.pageContent.content && (
  //         <Renderer
  //           info={this.props.info}
  //           content={this.props.pageContent.content}
  //         />
  //       )}

  //       {this.props.tags && (
  //         <div className="recipes-tags">
  //           <div className="recipes-tagsTitle">
  //             Recipe Categories
  //             <div className="recipes-clearTagsContainer">
  //               <div
  //                 className="recipes-clearTags"
  //                 onClick={() =>
  //                   this.setState({
  //                     selected: [],
  //                     search: "",
  //                     searched: false,
  //                     searchTags: []
  //                   })
  //                 }
  //               >
  //                 Clear Selection
  //               </div>
  //             </div>
  //           </div>
  //           {this.props.tags.map((tag: RecipeTag, i: number) => (
  //             <div className="recipes-tagContainer" key={i}>
  //               <div
  //                 className="recipes-tag"
  //                 style={{ backgroundColor: `${tag.color}` }}
  //                 onClick={() => {
  //                   this.setState(
  //                     {
  //                       selected: this.state.selected.concat([tag.name]),
  //                       search: this.state.search.includes(tag.name)
  //                         ? this.state.search
  //                         : this.state.search + " " + tag.name,
  //                       searchTags: this.state.searchTags.includes(tag.name)
  //                         ? this.state.searchTags
  //                         : this.state.searchTags.concat(tag.name)
  //                     },
  //                     () => this.search()
  //                   );
  //                 }}
  //               >
  //                 {tag.displayName}
  //               </div>
  //               <div className="recipes-tagFullname">{tag.name}</div>
  //             </div>
  //           ))}
  //         </div>
  //       )}

  //       <div className="recipes-search">
  //         <div className="recipes-searchBarAndIcon">
  //           <div
  //             className="recipes-searchIconContainer"
  //             onClick={() => this.search()}
  //           >
  //             <Icon className="recipes-searchIcon">search</Icon>
  //           </div>
  //           <input
  //             className="recipes-searchBar"
  //             name="recipes search bar"
  //             placeholder="Search for a recipe"
  //             value={this.state.search}
  //             onChange={e =>
  //               this.setState({
  //                 search: e.target.value,
  //                 searched: false,
  //                 searchTags: []
  //               })
  //             }
  //             onKeyUp={e => this.enterPress(e)}
  //           />
  //         </div>
  //         <div className="recipes-searchResultCount">
  //           {this.state.searched
  //             ? this.state.results.filter((result: boolean) => result).length >
  //               0
  //               ? `Displaying ${
  //                   this.state.results.filter((result: boolean) => result)
  //                     .length
  //                 } Result${
  //                   this.state.results.filter((result: boolean) => result)
  //                     .length > 1
  //                     ? "s"
  //                     : ""
  //                 }`
  //               : "No Results"
  //             : `Displaying ${this.state.searchText.length} Recipe${
  //                 this.state.searchText.length > 1 ? "s" : ""
  //               }`}
  //         </div>
  //       </div>
  //       <div className="recipes-resultsView">
  //         <div
  //           className={`recipes-resultLayoutOption ${
  //             this.state.resultsView === "grid"
  //               ? "recipes-resultLayoutActive"
  //               : "recipes-resultLayoutNonactive"
  //           }`}
  //           id="recipes-grid"
  //           onClick={() => this.setState({ resultsView: "grid" })}
  //         >
  //           <Icon className="recipes-resultLayoutOptionIcon">grid_on</Icon>
  //         </div>
  //         <div
  //           className={`recipes-resultLayoutOption ${
  //             this.state.resultsView === "title"
  //               ? "recipes-resultLayoutActive"
  //               : "recipes-resultLayoutNonactive"
  //           }`}
  //           id="recipes-title"
  //           onClick={() => this.setState({ resultsView: "title" })}
  //         >
  //           <Icon className="recipes-resultLayoutOptionIcon">dehaze</Icon>
  //         </div>
  //         <div
  //           className={`recipes-resultLayoutOption ${
  //             this.state.resultsView === "preview"
  //               ? "recipes-resultLayoutActive"
  //               : "recipes-resultLayoutNonactive"
  //           }`}
  //           id="recipes-preview"
  //           onClick={() => this.setState({ resultsView: "preview" })}
  //         >
  //           <Icon className="recipes-resultLayoutOptionIcon">view_compact</Icon>
  //         </div>
  //         <div className="recipes-layoutTitle">Layout</div>
  //       </div>
  //       <Separator background={true} />

  //       {this.props.recipes && (
  //         <div
  //           className={`recipes-recipesContainer ${"recipes-recipesContainer" +
  //             this.state.resultsView}`}
  //         >
  //           {this.props.recipes.map((recipe: Recipe, i: number) => {
  //             let image =
  //               recipe.images && recipe.images.length > 0
  //                 ? recipe.images[0].image
  //                 : false;
  //             if (
  //               !this.state.searched ||
  //               (this.state.searched && this.state.results[i])
  //             )
  //               return (
  //                 <div
  //                   className={`recipes-recipe ${"recipes-recipe" +
  //                     this.state.resultsView}`}
  //                   key={i}
  //                 >
  //                   <NavLink
  //                     to={"/recipes/" + makeLink(recipe.name)}
  //                     className="recipes-recipeLink"
  //                   >
  //                     <div className="recipes-recipeHeader">
  //                       <div className="recipes-recipeTitle">{recipe.name}</div>
  //                       {recipe.tags && (
  //                         <div className="recipes-recipeTags">
  //                           {recipe.tags.map((tag: RecipeTag, j: number) => (
  //                             <div
  //                               className="recipes-recipeTag"
  //                               style={{ backgroundColor: `${tag.color}` }}
  //                               key={j}
  //                             >
  //                               {tag.displayName}
  //                             </div>
  //                           ))}
  //                         </div>
  //                       )}
  //                     </div>
  //                     <div
  //                       className="recipes-recipeImage"
  //                       style={{ backgroundImage: `url(${image})` }}
  //                     >
  //                       {!image && recipe.overview && (
  //                         <Renderer
  //                           content={recipe.overview}
  //                           info={this.props.info}
  //                         />
  //                       )}
  //                     </div>
  //                     <div className="recipes-recipePreview">
  //                       {recipe.overview && recipe.images && (
  //                         <ImagePane {...recipe.images} />
  //                       )}
  //                       {recipe.overview && (
  //                         <Renderer
  //                           content={recipe.overview}
  //                           info={this.props.info}
  //                         />
  //                       )}
  //                       )}
  //                     </div>
  //                     <div className="recipes-recipeRead"> Read More </div>
  //                   </NavLink>
  //                 </div>
  //               );
  //             else return null;
  //           })}
  //         </div>
  //       )}
  //     </div>
  //   );
  // }
}

export default Recipes;
