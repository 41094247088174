import React, { Component } from "react";
import { ArticleProps } from "../../../Interfaces/Props";
import Renderer from "../Renderer/Renderer";

import "./NewsArticle.scss";

class NewsArticle extends Component<ArticleProps> {
  render() {
    return (
      <div
        id="article"
        style={{ backgroundImage: `url(${this.props.background})` }}
      >
        <div id="article-titleContainer">
          <div id="article-dateContainer">
            <div id="article-date">{this.props.article.date}</div>

            <div id="article-title">{this.props.article.title}</div>
          </div>
        </div>
        <Renderer content={this.props.article.content} info={this.props.info} />
      </div>
    );
  }
}

export default NewsArticle;
