import './Footer.scss';

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import bike from '../../../Media/Graphics/bike.png';
import Bam from '../bam/Bam';
import Social from '../Social/Social';
import { isActive } from '../Tools/LinkTools';

class Footer extends Component<any> {
  render() {
    let date = new Date();
    const year = date.getFullYear();

    return (
      <div
        className={`footer ${
          this.props.onEatRightPage ? "footer-eat" : "footer-kristie"
        }`}
      >
        <div className="footer-imageContainer">
          <NavLink to="/" className="footer-homeLink">
            <img
              src={bike}
              alt="Page footer bike graphic"
              className="footer-bikeGraphic"
            />
          </NavLink>
        </div>
        <div className="footer-links">
          <NavLink
            exact={true}
            to="/expertise"
            className="footer-link"
            activeClassName="footer-linkActive"
            isActive={isActive.bind(this, "/expertise")}
          >
            EXPERTISE
          </NavLink>
          <NavLink
            exact={true}
            to="/eatRightBucksCounty"
            className="footer-link"
            activeClassName="footer-linkActive"
            isActive={isActive.bind(this, "/eatRightBucksCounty")}
          >
            {`EAT RIGHT\nBUCKS COUNTY`}
          </NavLink>
          <NavLink
            exact={true}
            to="/recipes"
            className="footer-link"
            activeClassName="footer-linkActive"
            isActive={isActive.bind(this, "/recipes")}
          >
            RECIPES
          </NavLink>
          <NavLink
            exact={true}
            to="/news"
            className="footer-link"
            activeClassName="footer-linkActive"
            isActive={isActive.bind(this, "/news")}
          >
            NEWS
          </NavLink>
        </div>
        <div className="footer-info">
          <div id="footer-copy">&copy; {year} Kristie Finnan</div>
          <div id="footer-socialIcons">
            <Social icons={this.props.icons} />
          </div>
          <div id="footer-built">
            <Bam />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
