import React, { Component } from "react";
import "./Scheduler.scss";

class Scheduler extends Component {
  render() {
    return (
      <div id="scheduler">
        <iframe
          src="https://app.acuityscheduling.com/schedule.php?owner=11203157"
          width="100%"
          height="800"
          frameBorder="0"
          title="Schedule an appointment here!"
        ></iframe>
        <script
          src="https://embed.acuityscheduling.com/js/embed.js"
          type="text/javascript"
        ></script>
      </div>
    );
  }
}

export default Scheduler;
