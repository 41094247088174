import React, { Component } from "react";
import { SocialIcons } from "../../../Interfaces/Props";

import "./Social.scss";

class Social extends Component<SocialIcons> {
  render() {
    return (
      <div id="social" className={`${this.props.eat ? "eat" : "kristie"}`}>
        {this.props.icons &&
          this.props.icons.map(
            (data: { icon: string; link: string }, i: number) => (
              <div className="social-iconContainer" key={i}>
                <a href={data.link} className="social-iconLink">
                  <img
                    className="social-iconImage"
                    alt="Social Media Icon"
                    src={data.icon}
                  />
                </a>
              </div>
            )
          )}
      </div>
    );
  }
}

export default Social;
