import React, { Component } from "react";
import { Header } from "../../../Interfaces/Props";

import "./HeaderImage.scss";

class HeaderImage extends Component<Header> {
  render() {
    if (this.props.image)
      return (
        <div
          className={`${this.props.eat ? "eat" : "kristie"}`}
          id="header"
          style={{
            backgroundImage: `url(${this.props.image})`
          }}
        >
          {this.props.text && (
            <h1 className="header-text">
              <div>{this.props.text}</div>
            </h1>
          )}
        </div>
      );
    return (
      <h1
        className={`header-text ${this.props.eat ? "eat" : "kristie"}`}
        id="header-noImage"
      >
        <div>{this.props.text}</div>
      </h1>
    );
  }
}

export default HeaderImage;
