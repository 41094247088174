import React, { Component } from "react";
import { parseParagraph } from "../Renderer/Renderer";
import { Image } from "../../../Interfaces/Props";
import "../Renderer/Renderer.scss";

import "./ImagePane.scss";
import ScrollAnimation from "react-animate-on-scroll";

interface Heights {
  [idx: number]: string;
}

class ImagePane extends Component<Image[]> {
  state: {
    images: Image[];
    selectedImage: number;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      images: Object.entries(props).map((value: any) => value[1] as Image),
      selectedImage: 0
    };
  }

  render() {
    if (!this.state.images[this.state.selectedImage]) return <></>;
    return (
      <ScrollAnimation
        animateIn="fadeIn"
        className="imagePane-container"
        animateOnce={true}
      >
        <div className="imagePane" key={Math.random()}>
          {this.state.images.length > 1 && (
            <div className="imagePane-imageTiles">
              {this.state.images.map((image: Image, i: number) => (
                <div
                  key={i}
                  onClick={() => {
                    if (this.state.selectedImage === i) return;
                    this.setState({ selectedImage: i });
                  }}
                  className={`imagePane-imageTile ${
                    this.state.selectedImage === i
                      ? "imagePane-imageTileSelected"
                      : "imagePane-imageTileUnselected"
                  }`}
                  style={{
                    backgroundImage: `url(${image.image})`
                  }}
                />
              ))}
            </div>
          )}

          <div
            style={{
              backgroundImage: `url(${this.state.images[this.state.selectedImage].image})`
            }}
            className="imagePane-mainImage"
          >
            <img
              className="imagePane-ghostImage"
              src={this.state.images[this.state.selectedImage].image}
              alt="kristie finnan dietitian content"
              style={{ visibility: "hidden" }}
            />
          </div>
          {this.state.images[this.state.selectedImage] &&
            this.state.images[this.state.selectedImage].caption !==
              undefined && (
              <div className="imagePane-caption">
                {parseParagraph(
                  this.state.images[this.state.selectedImage].caption!,
                  1
                )}
              </div>
            )}
        </div>
      </ScrollAnimation>
    );
  }
}

export default ImagePane;
