import React, { Component } from "react";
import { ProfileInfo } from "../../../Interfaces/Props";
import Helmet from "react-helmet";
import Renderer from "../Renderer/Renderer";

import "./Profile.scss";
import HeaderImage from "../HeaderImage/HeaderImage";

class Profile extends Component<ProfileInfo> {
  state: {
    description: string;
  };

  constructor(props: ProfileInfo) {
    super(props);
    this.state = {
      description: props.qualifications
        .concat(this.props.expertise)
        .map((info: string) => info)
        .join(" ")
    };
  }
  render() {
    return (
      <div
        id="profile"
        style={{ backgroundImage: `url(${this.props.background})` }}
      >
        <Helmet>
          <title>{this.props.name}</title>
          <meta name="description" content={this.state.description} />
        </Helmet>
        {this.props.content.header && (
          <HeaderImage eat={true} {...this.props.content.header} />
        )}
        {this.props.content.content && (
          <Renderer
            eatTheme={true}
            content={this.props.content.content}
            info={this.props.content.info}
          />
        )}
      </div>
    );
  }
}

export default Profile;
