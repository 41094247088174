import React, { Component } from "react";
import {
  RecipeContent,
  Ingredient,
  Paragraph,
  RecipeTag
} from "../../../Interfaces/Props";

import "./RecipeListing.scss";
import Renderer, { parseParagraph } from "../Renderer/Renderer";
import ImagePane from "../ImagePane/ImagePane";

class RecipeListing extends Component<RecipeContent> {
  state = {
    nutrition: []
  };

  constructor(props: RecipeContent) {
    super(props);
    this.state = {
      nutrition: []
    };
  }

  render() {
    return (
      <div
        id="recipe"
        style={{ backgroundImage: `url(${this.props.background})` }}
      >
        <div className="recipe-title">{this.props.recipe.name}</div>
        <div className="recipe-ingredients">
          <div className="recipe-ingredientsTitle"> Ingredients: </div>
          <ul className="recipe-ingredientsList">
            {this.props.recipe.ingredients.map(
              (ingredient: Ingredient, i: number) => (
                <li
                  className="recipe-ingredient"
                  key={i}
                >{` ${ingredient.quantity} ${ingredient.unit} ${ingredient.name}`}</li>
              )
            )}
          </ul>
        </div>
        <div className="recipe-instructions">
          <div className="recipe-instructionsTitle"> Instructions: </div>
          <ol className="recipe-instructionsList">
            {this.props.recipe.instructions.map(
              (instruction: Paragraph, i: number) => (
                <li className="recipe-instruction" key={i}>
                  {parseParagraph(instruction, i)}
                </li>
              )
            )}
          </ol>
        </div>
        {this.props.recipe.images && (
          <div className="recipe-images">
            <ImagePane {...this.props.recipe.images} />
          </div>
        )}
        {this.props.recipe.notes && (
          <Renderer info={this.props.info} content={this.props.recipe.notes} />
        )}
        {this.props.recipe.tags && (
          <div className="recipe-tags">
            {this.props.recipe.tags.map((tag: RecipeTag, i: number) => (
              <div
                className="recipe-tag"
                style={{ backgroundColor: `${tag.color}` }}
                key={i}
              >
                {tag.displayName}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default RecipeListing;
