import React, { Component } from "react";
import Helmet from "react-helmet";
import { PageContent } from "../../../../../Interfaces/Props";
import HeaderImage from "../../../HeaderImage/HeaderImage";
import Renderer from "../../../Renderer/Renderer";

import "./First.scss";

class First extends Component<PageContent> {
  render() {
    return (
      <div
        id="first"
        style={{
          backgroundImage: `url(${this.props.background})`
        }}
      >
        <Helmet>
          <title>First Visit</title>
          <meta
            name="description"
            content="Everything you need to have ready for your first visit at Eat Right Bucks County"
          />
        </Helmet>
        {this.props.header && <HeaderImage eat={true} {...this.props.header} />}
        <Renderer
          content={this.props.content}
          info={this.props.info}
          eatTheme={true}
        />
      </div>
    );
  }
}

export default First;
