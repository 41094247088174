import React, { Component } from "react";
import Helmet from "react-helmet";
import { ExpertisePage, Content } from "../../../../Interfaces/Props";
import HeaderImage from "../../HeaderImage/HeaderImage";
import { makeLink } from "../../Tools/LinkTools";

import "./Expertise.scss";
import Renderer from "../../Renderer/Renderer";
import Separator from "../../Separator/Separator";

class Expertise extends Component<ExpertisePage> {
  scrollTo(specialty: string) {
    let area = document.getElementById(makeLink(specialty));
    if (area)
      window.scrollTo({
        top: area.offsetTop - 50,
        left: 0,
        behavior: "smooth"
      });
  }

  render() {
    return (
      <div
        id="expertise"
        style={{ backgroundImage: `url(${this.props.background})` }}
      >
        <Helmet>
          <title>My Expertise</title>
          <meta
            name="description"
            content="My dietitian expertise on a variety of dietary health issues"
          />
        </Helmet>
        {this.props.header && (
          <HeaderImage eat={false} {...this.props.header.header} />
        )}
        {this.props.header && this.props.header.content && (
          <Renderer
            content={this.props.header.content}
            info={this.props.info}
          />
        )}
        {this.props.specialties && (
          <div className="expertise-specialties">
            <div className="expertise-specialtiesLinks">
              {this.props.specialties.map(
                (
                  specialty: { title: string; content?: Content[] },
                  i: number
                ) => (
                  <div
                    className="expertise-link"
                    onClick={() => this.scrollTo(specialty.title)}
                    key={i}
                  >
                    {specialty.title}
                  </div>
                )
              )}
            </div>
            <Separator background={true} />
            <div className="expertise-specialtiesContent">
              {this.props.specialties.map(
                (
                  specialty: { title: string; content: Content[] },
                  i: number
                ) => (
                  <div
                    key={i}
                    className="expertise-specialty"
                    id={makeLink(specialty.title)}
                  >
                    <Renderer
                      content={specialty.content}
                      info={this.props.info}
                    />
                    {this.props.specialties &&
                      i < this.props.specialties.length - 1 && (
                        <Separator background={true} />
                      )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Expertise;
