import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { SeparatorProps } from "../../../Interfaces/Props";

import "./Separator.scss";

class Separator extends Component<SeparatorProps> {
  state: { rendered: boolean };

  constructor(props: any) {
    super(props);
    this.state = { rendered: false };
  }
  render() {
    return (
      <ScrollAnimation
        animateIn="fadeIn"
        animateOut="fadeOut"
        animateOnce={false}
        offset={100}
        duration={0}
        afterAnimatedIn={() => this.setState({ rendered: true })}
        afterAnimatedOut={() => this.setState({ rendered: false })}
      >
        <div
          id="separatorContainer"
          className={`${
            this.props.background ? "separatorBackground" : "separatorNone"
          } ${this.props.eat ? "eat" : "kristie"}`}
        >
          <div
            className={`${
              this.state.rendered ? "separatorLineGrown" : undefined
            } separatorLine separatorLineDark`}
          />
          <div
            className={`${
              this.state.rendered ? "separatorLineGrown" : undefined
            } separatorLine separatorLineLight`}
          />
          <div
            className={`${
              this.state.rendered ? "separatorLineGrown" : undefined
            } separatorLine separatorLineDark`}
          />
        </div>
      </ScrollAnimation>
    );
  }
}

export default Separator;
