import React, { Component } from "react";
import Helmet from "react-helmet";
import { PageContent } from "../../../../../Interfaces/Props";

import "./Contact.scss";
import Renderer from "../../../Renderer/Renderer";
import HeaderImage from "../../../HeaderImage/HeaderImage";

class Contact extends Component<PageContent> {
  render() {
    return (
      <div
        id="contact"
        style={{ backgroundImage: `url(${this.props.background})` }}
      >
        <Helmet>
          <title>Eat Right Bucks County</title>
          <meta
            name="description"
            content="Find out how Eat Right Bucks County can improve you dietary health."
          />
        </Helmet>
        {this.props.header && <HeaderImage eat={true} {...this.props.header} />}
        <Renderer
          eatTheme={true}
          info={this.props.info}
          content={this.props.content}
        />
      </div>
    );
  }
}

export default Contact;
