import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Content,
  StyledText,
  Link,
  Paragraph,
  ParagrapsAndImage,
  Image,
  List,
  FileDownload,
  ProfileInfo,
  ContactForm,
  RendererProps
} from "../../../Interfaces/Props";
import ImagePane from "../ImagePane/ImagePane";
import { NavLink } from "react-router-dom";
import Contact from "../Contact/Contact";
import Separator from "../Separator/Separator";
import ScrollAnimation from "react-animate-on-scroll";
import Map from "../Map/Map";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import { makeLink } from "../Tools/LinkTools";

import "./Renderer.scss";
import Scheduler from "../Scheduler/Scheduler";

interface KeyToString {
  [key: number]: Image;
}

class Renderer extends Component<RendererProps> {
  state: {
    content: Content[];
    rendered: JSX.Element | boolean;
  };
  selectedImages: KeyToString;

  constructor(props: RendererProps) {
    super(props);
    this.state = {
      content: this.props.content,
      rendered: false
    };
    this.selectedImages = {};
  }

  componentDidMount() {
    this.setState(
      {
        content: this.props.content
      },
      () => this.parseAll()
    );
  }

  render() {
    return (
      <div className={`content ${this.props.eatTheme ? "eat" : "kristie"}`}>
        {this.state.rendered}
      </div>
    );
  }

  parseAll() {
    if (this.props.content.length === 0) {
      this.setState({ rendered: false }, () =>
        window.setTimeout(() => this.parseAll())
      );
      return;
    }
    let result = this.props.content.map((item: Content, i: number) => {
      let imageKey = Number.parseInt(i.toString() + "1");
      let paragraphImageKey = Number.parseInt(i.toString() + "2");
      if (!this.selectedImages[imageKey] && item.images) {
        this.selectedImages[imageKey] = item.images[0];
      }
      if (!this.selectedImages[paragraphImageKey] && item.paragraphsAndImage) {
        this.selectedImages[paragraphImageKey] =
          item.paragraphsAndImage.image[0];
      }
      return (
        <div key={i} className="content-item">
          {this.parseContent(item, i)}
        </div>
      );
    });
    this.setState({ rendered: result });
  }

  parseContent(item: Content, key: number) {
    const result = [];
    if (item.title && item.showTitle)
      result.push(
        <h2 className="content-titleContainer" key={Math.random()}>
          <ScrollAnimation offset={0} animateOnce={true} animateIn="fadeIn">
            <div className="content-title" key={Math.random()}>
              {item.title}
            </div>
          </ScrollAnimation>
        </h2>
      );

    if (item.subtitle)
      result.push(
        <h3 className="content-subtitleContainer" key={Math.random()}>
          <ScrollAnimation offset={0} animateIn="fadeIn" animateOnce={true}>
            <div className="content-subtitle">{item.subtitle}</div>
          </ScrollAnimation>
        </h3>
      );

    if (item.list && item.list.content && item.list.content.length > 0)
      result.push(parseList(item.list));

    if (item.paragraphs && item.paragraphs.length > 0)
      result.push(parseParagraphs(item.paragraphs));

    if (item.paragraphsAndImage)
      result.push(
        parseImageAndParagraphs(
          item.paragraphsAndImage,
          parseImages(
            item.paragraphsAndImage.image,
            Number.parseInt(key.toString() + "2")
          )
        )
      );

    if (item.images)
      result.push(
        parseImages(item.images, Number.parseInt(key.toString() + "1"))
      );

    if (item.slideshow) result.push(parseSlideshow(item.slideshow));

    if (item.video) result.push(parseVideo(item.video));

    if (item.files) result.push(parseFiles(item.files));

    if (item.profiles) result.push(parseProfiles(item.profiles));

    if (item.scheduler) result.push(parseScheduler(item.scheduler));

    if (item.contact)
      result.push(parseContact({ ...item.contact, eat: this.props.eatTheme }));

    if (item.map) result.push(parseMap(item.map + ""));

    if (item.frame) result.push(parseIFrame(item.frame));

    if (item.separator)
      result.push(
        parseSeparator(
          item.separator,
          this.props.eatTheme ? this.props.eatTheme : false
        )
      );

    if (item.tags) result.push(parseTags(item.tags));

    return result;
  }
}

export default Renderer;

function iLiveDangerously(html: string) {
  return { __html: html };
}

export function parseImages(images: Image[], key?: number) {
  return <ImagePane key={Math.random()} {...images} />;
}

export function parseLinkAndStyle(
  text: string,
  styles: StyledText[],
  links: Link[]
) {
  let result = "";
  let remainingText = text;

  while (remainingText.length > 0) {
    // traverse string, split one at a time based on the nearest split index
    let link: Link = { url: "", text: "" };
    const closestSplit: {
      alterations: any;
      index: number;
    } = {
      alterations: link,
      index: Number.MAX_SAFE_INTEGER
    };

    if (styles.length !== 0)
      for (let i = 0; i < styles.length; i++) {
        let index = remainingText.indexOf(styles[i].text);
        if (index !== -1 && index < closestSplit.index) {
          closestSplit.index = index;
          closestSplit.alterations = styles[i];
        }
      }

    if (links.length !== 0)
      for (let i = 0; i < links.length; i++) {
        let index = remainingText.indexOf(links[i].text);
        if (index !== -1 && index < closestSplit.index) {
          closestSplit.index = index;
          closestSplit.alterations = links[i];
        }
      }

    if (closestSplit.index === Number.MAX_SAFE_INTEGER) {
      result = result + remainingText;
      remainingText = "";
      break;
    }

    let splitResult = remainingText.split(closestSplit.alterations.text, 1);

    let leftText = splitResult[0];
    let rightText = remainingText.substring(
      remainingText.indexOf(closestSplit.alterations.text) +
        closestSplit.alterations.text.length
    );

    if (!closestSplit.alterations.url) {
      let classList = "";

      if (closestSplit.alterations.bold) classList = "content-bold ";
      if (closestSplit.alterations.italics)
        classList = classList + "content-italics";
      let styledText = closestSplit.alterations.color ? (
        <span
          className={classList}
          style={{ color: ` ${closestSplit.alterations.color}` }}
        >
          {closestSplit.alterations.text}
        </span>
      ) : (
        <span className={classList}>{closestSplit.alterations.text}</span>
      );

      remainingText = rightText;

      if (result.length === 0) {
        result = leftText + ReactDOMServer.renderToStaticMarkup(styledText);
      } else {
        result =
          result + leftText + ReactDOMServer.renderToStaticMarkup(styledText);
      }
    } else {
      let linkText = (
        <a
          className="content-linkText"
          href={`${closestSplit.alterations.url}`}
        >
          {closestSplit.alterations.text}
        </a>
      );

      remainingText = rightText;

      if (result.length === 0) {
        result = leftText + ReactDOMServer.renderToStaticMarkup(linkText);
      } else {
        result =
          result + leftText + ReactDOMServer.renderToStaticMarkup(linkText);
      }
    }
  }

  return (
    <div
      className="content-styledText"
      dangerouslySetInnerHTML={iLiveDangerously(result)}
    />
  );
}

export function parseParagraph(paragraph: Paragraph, key: number) {
  let paragraphText = paragraph.text;
  paragraphText = ReactDOMServer.renderToStaticMarkup(
    parseLinkAndStyle(
      paragraphText,
      paragraph.styles ? paragraph.styles : [],
      paragraph.links ? paragraph.links : []
    )
  );

  return (
    <div
      key={key}
      className="content-paragraphText"
      dangerouslySetInnerHTML={iLiveDangerously(paragraphText)}
    />
  );
}

export function parseParagraphs(paragraphs: Paragraph[]) {
  if (paragraphs.length === 0) return;
  let resultText = "";
  paragraphs.map((paragraph: Paragraph, i: number) => {
    resultText += ReactDOMServer.renderToStaticMarkup(
      parseParagraph(paragraph, i)
    );
    return resultText; // shut up compiler
  });
  return (
    <ScrollAnimation
      offset={100}
      key={Math.random()}
      animateIn="fadeInUp"
      animateOnce={true}
    >
      <div
        className="content-paragraphs"
        key={Math.random()}
        dangerouslySetInnerHTML={iLiveDangerously(resultText)}
      ></div>
    </ScrollAnimation>
  );
}

export function parseList(list: List) {
  let listContent: JSX.Element[] = [];
  let title =
    list.showTitle && list.title ? (
      <ScrollAnimation offset={0} animateIn="fadeInUp" animateOnce={true}>
        <div className="content-listTitle">{list.title}</div>
      </ScrollAnimation>
    ) : (
      ""
    );

  list.content.map((paragraph: Paragraph, i: number) => {
    let result = parseParagraph(paragraph, i);
    listContent.push(
      <ScrollAnimation
        offset={0}
        key={i}
        animateIn="fadeInUp"
        animateOnce={true}
      >
        <li className="content-listElement">{result}</li>
      </ScrollAnimation>
    );
    return result;
  });

  if (list.numbered)
    return (
      <div className="content-listContainer" key={Math.random()}>
        {title}
        <ol className="content-list">
          {listContent.map((item: JSX.Element) => item)}
        </ol>
      </div>
    );

  return (
    <div className="content-listContainer" key={Math.random()}>
      {title}
      <ul className="content-list">
        {listContent.map((item: JSX.Element) => item)}
      </ul>
    </div>
  );
}

export function parseImageAndParagraphs(
  paragraphs: ParagrapsAndImage,
  image: JSX.Element
) {
  if (!paragraphs.paragraphs || paragraphs.paragraphs.length === 0) return;
  let text = parseParagraphs(paragraphs.paragraphs);

  if (paragraphs.alignment === "Left")
    return (
      <div key={Math.random()} className="content-paragraphsAndImage">
        <div className="content-halfWidth">{image}</div>
        <div className="content-halfWidth">{text}</div>
      </div>
    );

  return (
    <div key={Math.random()} className="content-paragraphsAndImage">
      <div className="content-halfWidth">{text}</div>
      <div className="content-halfWidth">{image}</div>
    </div>
  );
}

export function parseSlideshow(slideshow: any) {
  return <ImageCarousel key={Math.random()} images={slideshow} />;
}

export function parseVideo(video: string) {
  return (
    <div className="content-videoContainer">
      <iframe
        className="content-video"
        title={video}
        name="kristie finnan dietitian video"
        src={video}
      />
    </div>
  );
}

export function parseFiles(files: FileDownload[]) {
  return (
    <div key={Math.random()} className="content-fileDownloadsContainer">
      {files.map((file: FileDownload, i: number) => (
        <div key={i} className="content-fileDownloadContainers">
          <a
            className="content-fileDownload"
            href={file.file}
            download={file.text}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download {file.text}
          </a>
        </div>
      ))}
    </div>
  );
}

export function parseProfiles(profiles: ProfileInfo[]) {
  return (
    <div className="content-profileCards" key={Math.random()}>
      {profiles.map((profile: ProfileInfo, i: number) => (
        <div className="content-profilePadding" key={i}>
          <NavLink
            exact
            className="content-profileLink"
            to={`/eatRightBucksCounty/${makeLink(profile.name)}`}
          >
            <div key={i} className="content-profileCard">
              <div className="content-profilePicContainer">
                <div
                  className="content-profilePicture"
                  style={{ backgroundImage: `url(${profile.picture})` }}
                />
              </div>
              <div className="content-profileInfo">
                <div className="content-profileCardName">{profile.name}</div>
                <div className="content-profileQualificationsContainer">
                  {profile.qualifications.map(
                    (qualification: string, j: number) => (
                      <div key={j} className="content-profileQualification">
                        {j < profile.qualifications.length - 1
                          ? `${qualification}, `
                          : `${qualification}`}
                      </div>
                    )
                  )}
                </div>
                <div className="content-profileExpertiseContainer">
                  <div className="content-expertiseTitle">Expertise:</div>
                  {profile.expertise.map((expertise: string, j: number) => (
                    <span key={j} className="content-profileExpertise">
                      {j < profile.expertise.length - 1
                        ? `${expertise}, `
                        : `${expertise}`}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </div>
  );
}

export function parseScheduler(scheduler: boolean) {
  if (scheduler) return <Scheduler />;
}

export function parseContact(contact: ContactForm) {
  return (
    <Contact
      key={Math.random()}
      title={contact.title}
      email={contact.email}
      eat={contact.eat}
    />
  );
}

export function parseMap(map: string) {
  return <Map key={Math.random()} url={map} />;
}

export function parseIFrame(frame: string) {
  return (
    <div
      key={Math.random()}
      className="content-frameContainer"
      dangerouslySetInnerHTML={iLiveDangerously(frame)}
    ></div>
  );
}

export function parseSeparator(separator: boolean, eat: boolean) {
  return <Separator eat={eat} key={Math.random()} />;
}

export function parseTags(tags: string[]) {
  return (
    <div key={Math.random()} className="content-tagsContainer">
      {tags.map((tag: string, i: number) => (
        <div key={i} className="content-tag">
          {tag}
        </div>
      ))}
    </div>
  );
}
