import React, { Component } from "react";
import { Icon } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import { isActive, stopClickPropagation } from "../Tools/LinkTools";
import { SlideDown } from "react-slidedown";

import "./Nav.scss";
import Social from "../Social/Social";

class Nav extends Component<any> {
  state: {
    showStickyNav: boolean;
    topMobileLinksClosed: boolean;
    stickyMobileLinksClosed: boolean;
    topServicesClosed: boolean;
    stickyServicesClosed: boolean;
    eatRightTheme: boolean;
    rendered: boolean;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      showStickyNav: false,
      topMobileLinksClosed: true,
      stickyMobileLinksClosed: true,
      topServicesClosed: true,
      stickyServicesClosed: true,
      eatRightTheme: props.onEatRightPage,
      rendered: false
    };
  }

  componentDidMount() {
    this.setState({ rendered: true });
    if (window) {
      window.addEventListener("scroll", e => this.checkScrollPosition());
      window.addEventListener("resize", e =>
        this.setState({
          showTopMobileLinks: false,
          showStickyMobileLinks: false,
          stickyMobileLinksClosed: true,
          topMobileLinksClosed: true,
          topServicesClosed: true,
          stickyServicesClosed: true
        })
      );
    }
  }

  componentDidUpdate() {
    // prevent infinite render-update loop by only calling setState if there is a change
    if (this.props.onEatRightPage !== this.state.eatRightTheme)
      this.setState({ eatRightTheme: this.props.onEatRightPage });
  }

  checkScrollPosition() {
    if (window) {
      const scrollPos = window.pageYOffset;
      const nav = document.getElementById("nav-sticky");
      if (!nav) return;
      if (scrollPos - nav.clientHeight > 150) {
        this.setState({ showStickyNav: true });
      } else {
        this.setState({ showStickyNav: false, stickyMobileLinksClosed: true });
      }
    }
  }

  render() {
    return (
      <div
        id="nav"
        className={`${this.state.eatRightTheme ? "nav-eat" : "nav-kristie"}`}
        style={{ visibility: this.state.rendered ? "visible" : "hidden" }}
      >
        <div id="nav-top">
          <Social eat={this.state.eatRightTheme} icons={this.props.icons} />
          <div id="nav-topLogoContainer">
            <NavLink
              exact={true}
              to={this.state.eatRightTheme ? "/eatRightBucksCounty" : "/"}
              id="nav-topLogoHomeLink"
            >
              <img
                id="nav-topLogo"
                src={
                  this.state.eatRightTheme
                    ? this.props.eatBackground
                    : this.props.kristieBackground
                }
                alt={
                  this.state.eatRightTheme
                    ? "Eat Right Bucks County Logo"
                    : "Kristie Finnan Logo"
                }
              />
            </NavLink>
            <div id="nav-topMenuContainer" className="nav-menuContainer">
              <Icon
                id="nav-topMenuIcon"
                className="nav-menuIcon"
                onClick={() =>
                  this.setState({
                    topMobileLinksClosed: !this.state.topMobileLinksClosed
                  })
                }
              >
                menu
              </Icon>
            </div>
          </div>

          {/*Render both a desktop and a mobile nav, 
			   use css to hide the unused one*/}
          <div id="nav-topDesktopLinksContainer">{this.navLinks(true)}</div>
          <SlideDown
            className="nav-linksSlideDown nav-topLinksSlideDown"
            closed={this.state.topMobileLinksClosed}
            transitionOnAppear={true}
          >
            {this.navLinks(true)}
          </SlideDown>
        </div>

        <div
          id="nav-sticky"
          className={`${
            this.state.showStickyNav ? "nav-stickyNavigationShown" : undefined
          }`}
        >
          <div id="nav-stickyLogoContainer">
            <NavLink
              exact={true}
              to={this.state.eatRightTheme ? "/eatRightBucksCounty" : "/"}
              id="nav-stickyLogoHomeLink"
            >
              <img
                id="nav-stickyLogo"
                src={
                  this.state.eatRightTheme
                    ? this.props.eatBackground
                    : this.props.kristieBackground
                }
                alt={
                  this.state.eatRightTheme
                    ? "Eat Right Bucks County Logo"
                    : "Kristie Finnan Logo"
                }
              />
            </NavLink>
            <div id="nav-stickyMenuContainer" className="nav-menuContainer">
              <Icon
                id="nav-stickyMenuIcon"
                className="nav-menuIcon"
                onClick={() =>
                  this.setState({
                    stickyMobileLinksClosed: !this.state.stickyMobileLinksClosed
                  })
                }
              >
                menu
              </Icon>
            </div>
          </div>

          {/*Render both a desktop and a mobile nav, 
			   use css to hide the unused one*/}
          <div id="nav-stickyDesktopLinksContainer">{this.navLinks(false)}</div>
          <SlideDown
            className="nav-linksSlideDown nav-stickyLinksSlideDown"
            closed={this.state.stickyMobileLinksClosed}
            transitionOnAppear={true}
          >
            {this.navLinks(false)}
          </SlideDown>
        </div>
      </div>
    );
  }

  navLinks(top: boolean) {
    return (
      <div
        className={`nav-links ${
          this.state.stickyMobileLinksClosed
            ? "nav-mobileLinksHidden"
            : "nav-mobileLinksShown"
        }`}
        onClick={() =>
          this.setState({
            stickyMobileLinksClosed: true,
            topMobileLinksClosed: true,
            topServicesClosed: true,
            stickyServicesClosed: true
          })
        }
      >
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/")}
          >
            <span className="nav-linkText">HOME</span>
          </NavLink>
        </div>
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/expertise"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/expertise")}
          >
            <span className="nav-linkText">EXPERTISE</span>
          </NavLink>
        </div>
        <div
          className="nav-linkContainer"
          onMouseEnter={e => {
            stopClickPropagation(e);
            let links = document.getElementById(
              top ? "nav-serviceLinksTop" : "nav-serviceLinksSticky"
            );

            if (links) {
              links.classList.remove("nav-hidden");
            }
          }}
        >
          <NavLink
            exact={true}
            to="/eatRightBucksCounty"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/eatRightBucksCounty")}
          >
            <span className="nav-linkText">
              EAT RIGHT BUCKS COUNTY
              <div
                className="nav-expandServicesIconContainer"
                onClick={e => {
                  stopClickPropagation(e);
                  top
                    ? this.setState({
                        topServicesClosed: !this.state.topServicesClosed
                      })
                    : this.setState({
                        stickyServicesClosed: !this.state.stickyServicesClosed
                      });
                }}
              >
                <Icon className="nav-expandServicesIcon">
                  {top
                    ? this.state.topServicesClosed
                      ? "expand_more"
                      : "expand_less"
                    : this.state.stickyServicesClosed
                    ? "expand_more"
                    : "expand_less"}
                </Icon>
              </div>
            </span>
          </NavLink>
          <SlideDown
            className="nav-servicesSlideDown"
            closed={
              top
                ? this.state.topServicesClosed
                : this.state.stickyServicesClosed
            }
            transitionOnAppear={true}
          >
            <div
              id={`${top ? "nav-serviceLinksTop" : "nav-serviceLinksSticky"}`}
              className="nav-serviceLinks"
              onClick={() => {
                let links = document.getElementById(
                  top ? "nav-serviceLinksTop" : "nav-serviceLinksSticky"
                );
                if (links) links.classList.add("nav-hidden");
                this.setState({
                  showServicesSticky: false,
                  showServicesTop: false
                });
              }}
            >
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/eatRightBucksCounty/services"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(
                    this,
                    "/eatRightBucksCounty/services"
                  )}
                >
                  <span className="nav-linkText">SERVICES</span>
                </NavLink>
              </div>
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/eatRightBucksCounty/firstVisit"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(
                    this,
                    "/eatRightBucksCounty/firstVisit"
                  )}
                >
                  <span className="nav-linkText">YOUR FIRST VISIT</span>
                </NavLink>
              </div>
              <div className="nav-serviceLinkContainer">
                <NavLink
                  exact={true}
                  to="/eatRightBucksCounty/contact"
                  className="nav-serviceLink"
                  activeClassName="nav-link-active"
                  isActive={isActive.bind(this, "/eatRightBucksCounty/contact")}
                >
                  <span className="nav-linkText">CONTACT</span>
                </NavLink>
              </div>
            </div>
          </SlideDown>
        </div>
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/recipes"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/recipes")}
          >
            <span className="nav-linkText">RECIPES</span>
          </NavLink>
        </div>
        <div className="nav-linkContainer">
          <NavLink
            exact={true}
            to="/news"
            className="nav-link"
            activeClassName="nav-link-active"
            isActive={isActive.bind(this, "/news")}
          >
            <span className="nav-linkText">NEWS</span>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default withRouter(Nav);
