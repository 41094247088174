import './News.scss';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import { Icon } from '@material-ui/core';

import { Article, Content, NewsPage } from '../../../../Interfaces/Props';
import HeaderImage from '../../HeaderImage/HeaderImage';
import Renderer from '../../Renderer/Renderer';
import Separator from '../../Separator/Separator';
import { makeLink } from '../../Tools/LinkTools';

class News extends Component<NewsPage> {
  state: {
    searchText: string[];
    searched: boolean;
    search: string;
    results: boolean[];
    loaded: boolean;
    resultsView: string;
  };

  constructor(props: NewsPage) {
    super(props);
    this.state = {
      searchText: [],
      searched: false,
      search: "",
      results: [],
      loaded: false,
      resultsView: "grid"
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  componentDidUpdate(props: NewsPage) {
    if (
      !props.articles ||
      props.articles.length === this.state.searchText.length
    )
      return;
    this.setState({
      searchText: props.articles.map((article: Article) =>
        this.extractValues(article)
      )
    });
  }

  extractValues(obj: Object): string {
    return Object.values(obj)
      .map((value: any) => {
        if (typeof value === "object") {
          return this.extractValues(value);
        }
        return value;
      })
      .join(" ")
      .toLowerCase();
  }

  search() {
    if (this.state.search.length > 0) {
      this.setState({ searched: true });
      this.setState({
        results: this.state.searchText.map((text: string) =>
          text.includes(this.state.search.toLowerCase()) ? true : false
        )
      });
      let service = document.getElementById("news-search");
      if (service) {
        window.scrollTo({
          top: service.offsetTop - 50,
          left: 0,
          behavior: "smooth"
        });

        // hide the mobile keyboard
        let element = document.getElementById("news-searchBar");
        if (element !== null) {
          // Force keyboard to hide on input field.
          element.setAttribute("readonly", "readonly");
          // Force keyboard to hide on textarea field.
          element.setAttribute("disabled", "true");
          setTimeout(function() {
            if (element !== null) {
              //actually close the keyboard
              element.blur();
              // Remove readonly attribute after keyboard is hidden.
              element.removeAttribute("readonly");
              element.removeAttribute("disabled");
            }
          }, 100);
        }
      }
    }
  }

  firstImage(content: Content[]) {
    for (let i = 0; i < content.length; i++) {
      let item = content[i];
      if (
        item.paragraphsAndImage &&
        item.paragraphsAndImage.image &&
        item.paragraphsAndImage.image[0] &&
        item.paragraphsAndImage.image[0].image
      )
        return item.paragraphsAndImage.image[0].image;
      if (item.images && item.images[0]) return item.images[0].image;
      if (item.slideshow) return item.slideshow.images[0].url;
    }
    return false;
  }

  enterPress(e: any) {
    if (e.keyCode === 13) this.search();
  }

  render() {
    return (
      <div
        id="news"
        style={{
          backgroundImage: `url(${this.props.background})`,
          visibility: this.state.loaded ? "visible" : "hidden"
        }}
      >
        <Helmet>
          <title>News</title>
          <meta
            name="description"
            content="Learn about krisite finnan's best health tips, Eat Right Bucks County updates, and anything health related"
          />
        </Helmet>
        {this.props.pageContent && this.props.pageContent.header && (
          <HeaderImage {...this.props.pageContent.header} />
        )}
        <Renderer
          content={this.props.pageContent.content}
          info={this.props.info}
        />

        <div className="news-search">
          <div className="news-searchBarAndIcon">
            <div
              className="news-searchIconContainer"
              onClick={() => this.search()}
            >
              <Icon className="news-searchIcon">search</Icon>
            </div>
            <input
              className="news-searchBar"
              name="news search bar"
              placeholder="Search for an article"
              value={this.state.search}
              onChange={e =>
                this.setState({ search: e.target.value, searched: false })
              }
              onKeyUp={e => this.enterPress(e)}
            />
          </div>
          <div className="news-searchResultCount">
            {this.state.searched
              ? this.state.results.filter((result: boolean) => result).length >
                0
                ? `Displaying ${
                    this.state.results.filter((result: boolean) => result)
                      .length
                  } Result${
                    this.state.results.filter((result: boolean) => result)
                      .length > 1
                      ? "s"
                      : ""
                  }`
                : "No Results"
              : `Displaying ${this.state.searchText.length} Articles${
                  this.state.searchText.length > 1 ? "s" : ""
                }`}
          </div>
        </div>
        <div className="news-resultsView">
          <div
            className={`news-resultLayoutOption ${
              this.state.resultsView === "grid"
                ? "news-resultLayoutActive"
                : "news-resultLayoutNonactive"
            }`}
            id="news-grid"
            onClick={() => this.setState({ resultsView: "grid" })}
          >
            <Icon className="news-resultLayoutOptionIcon">grid_on</Icon>
          </div>
          <div
            className={`news-resultLayoutOption ${
              this.state.resultsView === "title"
                ? "news-resultLayoutActive"
                : "news-resultLayoutNonactive"
            }`}
            id="news-title"
            onClick={() => this.setState({ resultsView: "title" })}
          >
            <Icon className="news-resultLayoutOptionIcon">dehaze</Icon>
          </div>
          <div
            className={`news-resultLayoutOption ${
              this.state.resultsView === "preview"
                ? "news-resultLayoutActive"
                : "news-resultLayoutNonactive"
            }`}
            id="news-preview"
            onClick={() => this.setState({ resultsView: "preview" })}
          >
            <Icon className="news-resultLayoutOptionIcon">view_compact</Icon>
          </div>
          <div className="news-layoutTitle">Layout</div>
        </div>
        <Separator background={true} />

        {this.props.articles && (
          <div className="news-articlesContainer">
            {this.props.articles.map((article: Article, i: number) => {
              let image = this.firstImage(article.content);
              if (
                !this.state.searched ||
                (this.state.searched && this.state.results[i])
              )
                return (
                  <div
                    className={`news-article ${"news-article" +
                      this.state.resultsView}`}
                    key={i}
                  >
                    <NavLink
                      to={"/news/" + makeLink(article.title + article.date)}
                      className="news-articleLink"
                    >
                      <div className="news-articleHeader">
                        <div className="news-articleDate">{article.date}</div>

                        <div className="news-articleTitle">{article.title}</div>
                      </div>
                      <div
                        className="news-articleImage"
                        style={{ backgroundImage: `url(${image})` }}
                      >
                        {!image && (
                          <Renderer
                            content={article.content}
                            info={this.props.info}
                          />
                        )}
                      </div>
                      <div className="news-articlePreview">
                        <Renderer
                          content={article.content}
                          info={this.props.info}
                        />
                      </div>
                      <div className="news-articleRead"> Read More </div>
                    </NavLink>
                  </div>
                );
              else return null;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default News;
